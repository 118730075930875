<template>
  <div>

    <div 
      class="d-flex justify-start align-center px-5" 
      style="height:60px;background-color:#fff;">
      <v-icon color="#d31145" @click="goback">
        mdi-arrow-left
      </v-icon>
      <span style="padding-left:15px;font-weight:700;color:#d31145">
        {{ $route.query.id ? 'Ubah Pegawai' : 'Tambah Pegawai' }}
      </span>
    </div>

    <v-form>
      <div style="height:calc(100vh - 100px);overflow:auto;" class="py-10 px-6">
        <div class="d-flex justify-center align-center py-4">
          <v-card
            flat
            class="pa-0 c-p"
            style="background: transparent;">
            <image-input v-model="avatar" type="uploadCover">
              <div slot="activator">
                <v-avatar
                  tile
                  size="120"
                  class="grey lighten-1 cursor-pointer rounded-xl">
                  <v-img
                    v-if="!avatar && addImage === ''"
                    :src="form.image_url"
                    class="mx-auto"
                    width="120"
                    height="120"
                    gradient="to top right, rgba(0,0,0,.1), rgba(0,0,0,.1)">
                    <v-icon
                      v-if="!avatar && addImage === '' && form.image_url === ''"
                      color="white"
                      style="z-index:2"
                      size="40">
                      mdi-camera-plus
                    </v-icon>
                  </v-img>

                  <div
                    class="px-5"
                    v-if="addImage && avatar.url === undefined">
                    <v-progress-linear
                      color="#d31145"
                      indeterminate
                      rounded
                      height="6"
                      class="mx-4 mt-4"
                      style="width: 80px;">
                    </v-progress-linear>

                    <p class="mt-2 mx-4 mb-0" style="font-size: 11px;">
                      Mengupload Gambar . . .
                    </p>
                  </div>

                  <v-img
                    v-if="avatar && !addImage"
                    :src="form.image_url"
                    :alt="form.image_url"
                    class="ma-auto"
                    gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"
                    width="120"
                    height="120">
                    <v-icon
                      color="white"
                      size="40"
                      v-if="avatar && !addImage">
                      mdi-camera-retake
                    </v-icon>
                  </v-img>
                </v-avatar>
              </div>
            </image-input>
          </v-card>

          <p class="caption mt-1 mb-1 red--text" v-show="error.image_url">
            {{ error.image_url }}
          </p>
        </div>

        <v-row>
          <v-col cols="12">
            <div class="mb-2 subtitle-2 font-weight-bold text-title">
              Nama Lengkap
            </div>
            <v-text-field
              v-model="form.fullname"
              placeholder="Tuliskan disini"
              required
              color="#d31145"
              maxlength="50"
              autocomplete="off"
              hide-details="auto">
            </v-text-field>

            <div style="display: flex;justify-content:space-between;padding-top:8px;">

              <div class="text-required">
                Wajib
              </div>

              <div style="font-size: 11px;">

                <span style="font-weight: bold">
                  {{ form.fullname.length }} 
                </span>

                <span style="color:#B5B5B5"> 
                  dari 50 karakter
                </span>  
              </div>
            </div>
          </v-col>

          <v-col cols="12" v-if="!$route.query.id">

            <div class="mb-2 subtitle-2">
              <span class="font-weight-bold text-title">Email</span> 
              <span class="font-italic" style="color:#8d8d8d">
                (Google)
              </span>
            </div>

            <v-text-field
              v-model="form.email"
              placeholder="Tuliskan disini"
              required
              type="email"
              color="#d31145"
              maxlength="50"
              autocomplete="off"
              hide-details="auto">
            </v-text-field>

            <div style="display: flex;justify-content:space-between;padding-top:8px;">

              <div class="text-required">
                Wajib
              </div>

              <div style="font-size: 11px;">

                <span style="font-weight: bold">
                  {{ form.email.length }} 
                </span>

                <span style="color:#B5B5B5"> 
                  dari 50 karakter
                </span>  
              </div>
            </div>
          </v-col>

          <v-col cols="12">

            <div class="mb-2 subtitle-2 font-weight-bold text-title">
              Nomor HP
            </div>

            <v-text-field
              v-model="form.mobilephone"
              placeholder="Tuliskan disini"
              required
              type="number"
              color="#d31145"
              maxlength="15"
              autocomplete="off"
              hide-details="auto">
            </v-text-field>

            <div style="display: flex;justify-content:space-between;padding-top:8px;">

              <div class="text-required">
                Wajib
              </div>

              <div style="font-size: 11px;">
                
                <span style="font-weight: bold">
                  {{ form.mobilephone.length }} 
                </span>

                <span style="color:#B5B5B5"> 
                  dari 15 karakter
                </span>  
              </div>
            </div>
          </v-col>

          <v-col cols="12">

            <div class="mb-2 subtitle-2 font-weight-bold text-title">
              Alamat
            </div>

            <v-text-field
              v-model="form.address"
              placeholder="Tuliskan disini"
              required
              color="#d31145"
              maxlength="150"
              autocomplete="off"
              hide-details="auto">
            </v-text-field>

            <div style="display: flex;justify-content:space-between;padding-top:8px;">

              <div class="text-required">
                Optional
              </div>

              <div style="font-size: 11px;">
                
                <span style="font-weight: bold">
                  {{ form.address.length }} 
                </span>

                <span style="color:#B5B5B5"> 
                  dari 150 karakter
                </span>  
              </div>
            </div>
          </v-col>

          <v-col cols="12">

            <div class="d-flex justify-space-between">
              <div class="mb-2 subtitle-2 font-weight-bold text-title">
                Toko dan Hak Akses 
                <span class="text-required">
                  ( Wajib )
                </span>
              </div>
              <div 
                @click="dialog.selectStore = true;dialog.selection = 'store'"
                style="color:#d31145;cursor: pointer;" class="text-capitalize body-2">
                <v-icon size="20" color="#d31145">mdi-plus</v-icon>
                Tambahkan
              </div>
            </div>

            <v-alert
              v-if="form.store_array.length < 1"
              text
              dense
              color="#FF9800"
              icon="mdi-alert-outline">
              <div class="subtitle-2">
                Silahkan Tambahkan toko untuk mengatur hak akses bagi pegawai Anda
              </div>
            </v-alert>


            <div v-else v-for="(item, i) in form.store_array" :key="i" class="d-flex justify-space-between py-2">
              <div 
                class="pa-1 pl-4 subtitle-2 d-flex align-center"
                style="
                  border: 2px solid rgba(211, 17, 69, 0.2);
                  flex:1;
                  height: 30px;
                  color: #d31145;
                  border-radius: 5px 0 0 5px;
                  background-color: rgba(211, 17, 69, 0.1);">
                <!-- style="
                  border: 2px solid #d31145;
                  flex:1;
                  height: 30px;
                  color: #d31145;
                  border-radius: 5px 0px 0px 5px"> -->
                {{ item.name }}
              </div>
              <div 
                @click="selectRole(item)"
                class="d-flex justify-center align-center mr-3 pa-1 white--text subtitle-2"
                style="
                  cursor: pointer;
                  background-color: #d31145;
                  width: 100px;
                  height: 30px;
                  border-radius: 0px 5px 5px 0px;">
                <div>
                  <span class="text-capitalize">
                    {{ item.role }}
                  </span>
                  <v-icon small color="white" class="ml-4">mdi-pencil</v-icon>
                </div>
              </div>
              <div class="pa-1 pr-0">
                <v-icon @click="removeStore(i)" color="#d31145">mdi-delete</v-icon>
              </div>
            </div>
            
          </v-col>
        </v-row>

      </div>

      <div style="position:absolute;bottom:0;width:100%;background-color:#fff;height:60px" class="d-flex px-6 py-3">
        <v-btn 
          @click="toValidation()" 
          :loading="process.run" 
          :disabled="process.run"
          rounded
          style="flex:1"
          elevation="0" 
          color="#d31145" 
          class="text-capitalize white--text mr-2 d-flex justify-space-between">
          <v-icon color="white" left>mdi-check-circle-outline</v-icon>
          <span>
            {{ $route.query.id ? 'Simpan perubahan' : 'Tambahkan data' }}
          </span>
        </v-btn>
        <v-btn 
          @click="goback"
          :loading="process.run" 
          :disabled="process.run" 
          rounded 
          style="flex:.5"
          outlined
          elevation="0" 
          color="#d31145" 
          class="text-capitalize">
          <span>
            Batalkan
          </span>
        </v-btn>
      </div>

    </v-form>

    <v-dialog v-model="dialog.selectStore" scrollable persistent width="350">
      <v-card class="rounded-lg">

        <v-card-title 
          class="subtitle-1 text-capitalize font-weight-bold white--text justify-center py-2" 
          style="
            background-color: #d31145;
            height:50px;">
          <div 
            v-if="dialog.selection == 'store'" 
            style="
              flex: 1; 
              display: flex; 
              justify-content: center">
              Plih Toko ({{ user.profile ? user.profile.store_array.length : 0 }})
          </div>

          <div 
            v-else
            style="
              flex: 1; 
              display: flex; 
              justify-content: center">
              Plih Role (2)
          </div>

          <v-icon @click="dialog.selectStore = false" color="#fff">mdi-close-circle-outline</v-icon>
          
        </v-card-title>

        <v-divider></v-divider>
        
        <v-card-text class="px-0" style="height:300px">

          <v-card
            flat
            class="mx-auto">

            <v-list v-if="dialog.selection == 'store'" class="py-0">

              <v-list-item-group>

                <v-list-item
                  v-for="(item, i) in user.profile ? user.profile.store_array : []"
                  :key="i"
                  @click="AddStore(item,i)">

                  <v-list-item-avatar>
                    <img :src="item.image_url == '' ? require('@/assets/images/icon_store.png') : item.image_url" alt="" width="30">
                  </v-list-item-avatar>

                  <v-list-item-content>

                    <v-list-item-title v-text="item.name"></v-list-item-title>

                  </v-list-item-content>

                  <v-list-item-icon>

                    <v-icon color="#D0D0D0">
                      mdi-chevron-right
                    </v-icon>
                  
                  </v-list-item-icon>
                
                </v-list-item>
              
              </v-list-item-group>
            
            </v-list>

            <v-list v-if="dialog.selection == 'role'" class="py-0">

              <v-list-item-group>

                <v-list-item
                  v-for="(item, i) in role"
                  :key="i"
                  @click="updateRole(item,i)">

                  <v-list-item-icon>
                    <v-icon color="#d31145">
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>

                    <v-list-item-title class="text-capitalize">
                      {{ item.name }}
                    </v-list-item-title>

                  </v-list-item-content>

                  <v-list-item-icon>
                    
                    <v-icon color="#D0D0D0">
                      mdi-chevron-right
                    </v-icon>
                  
                  </v-list-item-icon>
                
                </v-list-item>
              
              </v-list-item-group>
            
            </v-list>
          
          </v-card>
        
        </v-card-text>
      
      </v-card>
    </v-dialog>


    <v-bottom-sheet v-model="action.confirmation" width="100%">
      <v-sheet
        class="text-left rounded-t-xl"
        height="214px"
        width="100%">
        <div 
          class="pa-4"
          style="
            font-size: 18px;
            color: #d31145; 
            font-weight: bold;
            height:60px;">
          {{ $route.query.id ? 'Ubah Pegawai' : 'Tambah Pegawai' }}
        </div>

        <v-divider></v-divider>

        <div class="d-flex flex-wrap align-content-center align-center" style="font-size:14px;padding:10px 20px 5px 20px;height:96px;">
          Apakah anda yakin ingin {{ $route.query.id ? 'mengubah' : 'menambahkan' }} Pegawai &nbsp;
          <span class="font-weight-bold text-title text-capitalize"> 
            {{ form.fullname }} 
          </span> &nbsp;
          ?
        </div>

        <div class="d-flex justify-center align-center px-4 pb-4" style="position:absolute;bottom:0;width:100%">
          <v-btn 
            @click="save()" 
            style="flex:1" 
            :loading="process.run" 
            :disabled="process.run" 
            rounded
            elevation="0" 
            color="#d31145" 
            class="body-1 d-flex justify-start text-capitalize mr-2 white--text">
            <v-icon>mdi-check-circle-outline</v-icon>

            <span style="display:flex;justify-content:end;width:100%">
              Ya, {{ $route.query.id ? 'Simpan Perubahan' : 'Tambahkan Data' }}
            </span>
          </v-btn>

          <v-btn
            rounded
            outlined
            style="flex: .5"
            color="#d31145"
            class="body-1 text-capitalize"
            :disabled="process.run"
            @click="action.confirmation = false">
            Batalkan
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <!-- <v-dialog v-model="action.confirmation" persistent width="370">
      <v-card flat style="border-radius:15px">
        <v-card-title class="subtitle-1 text-capitalize font-weight-bold white--text justify-center py-2" style="background-color:#d31145">
          <div 
            style="
              flex: 1; 
              display: flex; 
              justify-content: center">
            {{ $route.query.id ? 'Ubah data pegawai' : 'Tambah data pegawai' }}
          </div>
          <v-icon @click="action.confirmation = false" color="#fff">mdi-close-circle-outline</v-icon>
          
        </v-card-title>

        <v-card-text 
          class="text-center pb-0">

          <v-img
            contain
            width="100"
            height="100"
            class="mx-auto mt-3"
            :src="form.image_url">
          </v-img>

          <div class="mt-5">
            {{ form.fullname }}
          </div>

          <div>
            {{ form.email }}
          </div>

          <div>
            {{ form.mobilephone }}
          </div>

          <div class="mt-3 body-2">
            {{ form.address }}
          </div>

          <div style="border: 1px dashed #FA91AD;width: 100%;" class="mt-7"></div>

          <v-list dense>
            <v-list-item>
              <v-list-item-content class="text-start">
                <v-list-item-title style="color:#d31145" class="font-weight-bold text-capitalize">Toko</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title style="color:#d31145" class="font-weight-bold text-capitalize">Role</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-for="(item,i) in form.store_array" :key="i" class="py-0">
              <v-list-item-content class="text-start">
                <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle class="text-capitalize">{{ item.role }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-card-text>

        <v-card-actions class="pb-8 px-3">

          <v-btn 
          @click="save()" 
          :loading="process.run" 
          :disabled="process.run"
          rounded
          style="flex:1"
          elevation="0" 
          color="#d31145" 
          class="text-capitalize white--text">

          <v-icon color="white" left>
            mdi-check-circle-outline
          </v-icon>
          <span class="subtitle-2">
            {{ $route.query.id ? 'Simpan perubahan' : 'Tambahkan data' }}
          </span>
        </v-btn>

        <v-btn 
          @click="action.confirmation = false"
          :loading="process.run" 
          :disabled="process.run" 
          rounded 
          style="flex:1"
          outlined
          elevation="0" 
          color="#A8A8A8" 
          class="text-capitalize">

          <span>
            Batalkan
          </span>
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

  </div>
</template>

<script>
import ImageInput from "@/components/ImageInput";
export default {
  data: () => ({
    action: {
      form: false,
      confirmation: false,
      delete: false,
      detail: false,
    },
    detail: {},
    formError:{},
    form: {
      id: "",
      fullname: '',
      email: '',
      mobilephone: '',
      address: '',
      image_url: '',
      store_array: []
    },
    role: [
      {
        name: 'admin',
        icon: 'mdi-account-tie'
      },
      {
        name: 'kasir',
        icon: 'mdi-account-cash'
      }
    ],
    avatar: null,
    addImage: '',
    process: {
      run: false
    },
    error: {
      image_url: ''
    },
    dialog: {
      selectStore: false,
      selection: "store",
      store_id: ""
    }
  }),
  components: {
    ImageInput
  },
  watch: {
    avatar: {
      handler: function() {
        this.form.image_url = this.avatar.url;
        this.addImage = this.avatar.add;
      },
      deep: true,
    },
  },
  computed: {
    user(){
      return this.$store.state.user
    }
  },
  created() {

  },
  mounted(){
    this.avatar = {
      url: "https://sodapos.com/media/2021/12/image/pegawai-1638863969.png",
      add: false
    }
    this.form.image_url = "https://sodapos.com/media/2021/12/image/pegawai-1638863969.png";
    this.addImage = false

    this.getDetail()
  },
  methods: {
    goback() {
      this.$router.push('/master/user')
    },

    async getDetail(){
      this.$store.state.overlay.state = true
      let res = await this.$get(`user`)

      if (res.status == 200) {
        this.$store.state.overlay.state = false
        res.results.data.forEach( item => {
          if (this.$route.query.id == item.id) {
            this.avatar = {
              url: item !== undefined ? item.image_url : "https://sodapos.com/media/2021/12/image/pegawai-1638863969.png",
              add: false
            }

            this.addImage = false

            this.form = {
              id: item !== undefined ? item.id : "",
              fullname: item !== undefined ? item.fullname : "",
              email: item !== undefined ? item.email : "",
              mobilephone: item !== undefined ? item.mobilephone : "",
              address: item !== undefined ? item.address : "",
              image_url: item !== undefined ? item.image_url : "https://sodapos.com/media/2021/12/image/pegawai-1638863969.png",
              store_array: item !== undefined ? item.store_array : [],
            }
          }else{
            this.form = {
              id: "",
              fullname: '',
              email: '',
              mobilephone: '',
              address: '',
              image_url: 'https://sodapos.com/media/2021/12/image/pegawai-1638863969.png',
              store_array: []
            }
          }
        })
      }

      this.$store.state.overlay.state = false
      // this.$store.state.snackbar.state = true
      // this.$store.state.snackbar.content = res.message
    },

    // # START SET TOKO DAN ROLE
    AddStore(item){

      if (this.form.store_array.length > 0) {
        console.log(this.form.store_array);
        let array_store = this.form.store_array.filter(store => {
          return item.id == store.id
        });
        // console.log(array_store);

        if(array_store.length > 0 && item.id == array_store[0].id){
          this.dialog.selectStore = false
          this.$store.state.snackbar.state = true
          this.$store.state.snackbar.content = `Toko ${item.name} sudah di pilih. Silahkan pilih lainnya.`
          return
        }else{
          let data = {
            id:  item.id,
            name: item.name,
            role: 'admin'
          }

          this.form.store_array.push(data);
        }
      }else{
        let data = {
          id:  item.id,
          name: item.name,
          role: 'admin'
        }

        this.form.store_array.push(data);
      }

      // let data = {
      //   id:  item.id,
      //   name: item.name,
      //   role: 'admin'
      // }

      // this.form.store_array.push(data);
      this.dialog.selectStore = false
    },
    selectRole(item){
      this.dialog.store_id = item.id
      this.dialog.selectStore = true;
      this.dialog.selection = 'role'
    },
    updateRole(item){
      // console.log(item);
      let index = this.form.store_array.findIndex(arr => arr.id == this.dialog.store_id)
      // console.log(index);
      this.form.store_array[index].role = item.name
      this.dialog.selectStore = false
    },
    removeStore(index){
      this.form.store_array.splice(index, 1)
    },
    // # END SET TOKO DAN ROLE

    toValidation(){
      if(!this.form.fullname){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Nama Lengkap tidak boleh kosong"
      }else if (!this.form.email){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Email tidak boleh kosong"
      }else if(/@gmail\.com$/.test(this.form.email) ==  false){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Email wajib menggunakan Gmail"
      }else if (this.form.mobilephone == ''){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Nomor HP tidak boleh kosong"
      }else if (this.form.mobilephone.length <= 10){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Nomor HP tidak boleh kurang dari 10 karakter"
      }else if(this.form.store_array.length < 1) {
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Toko dan Role tidak boleh kosong"
      }else {
        this.action.confirmation = true
      }
    },
    async save(){
      this.$store.state.overlay.state = true
      this.action.confirmation = false
      this.process.run = true
      let url = ""
      let data = {}
      if (this.form.id == '') {
        data = {
          fullname: this.form.fullname,
          email: this.form.email,
          mobilephone: this.form.mobilephone,
          image_url: this.form.image_url,
          address: this.form.address,
          store_array: this.form.store_array,
        }
        url = "user/add"
      }else{
        data = {
          id: this.form.id,
          fullname: this.form.fullname,
          mobilephone: this.form.mobilephone,
          image_url: this.form.image_url,
          address: this.form.address,
          store_array: this.form.store_array,
        }
        url = "user/update"
      }

      let res = await this.$post(url, data)

      if (res.status == 200) {
        this.process.run = false
        this.$store.state.overlay.state = false
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = res.message
        this.$router.push('/master/user')
      }

      this.$store.state.overlay.state = false
      this.process.run = false

      if(res.status == 412) {
        Object.keys(res.results.data).forEach((item) => {
          this.$store.state.snackbar.state = true
          this.$store.state.snackbar.content = res.results.data[item]
        });
      }

      this.process.run = false
      this.$store.state.snackbar.state = true
      this.$store.state.snackbar.content = res.message
      this.$store.state.overlay.state = false;
    },
  },
}
</script>